import React, { useState } from 'react';
import LayoutHeaderFooter from '../../components/LayoutHeaderFooter/LayoutHeaderFooter';
import Button from '../../components/Button/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import FirstComponentSmall from '../../components/FirstComponentSmall/FirstComponentSmall';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SuccessCheckMark from '../../components/SuccessCheckMark/SuccessCheckMark';
import CMOCheckbox from '../../templates/ContactForm/Checkbox/Checkbox';
import ClockLoader from 'react-spinners/ClockLoader';
import { css } from '@emotion/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import recaptchaRef from '../../templates/ContactForm/recaptchaRef';
import './requestForm.scss';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const RequestForm = (props) => {
  const override = css`
    display: block;
    margin: 0 auto;
    margin-bottom: 50px;
    box-shadow: none;
    border: 4px solid #4caf50;
  `;


  const {
    title,
    Primary_color,
    Secondary_color,
    footNote,
    productKey
  } = props.pageContext.content;

  const [surName, setSurname] = useState('');
  const [surNameError, setSurNameError] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [cegNev, setCegnev] = useState('');
  const [cegNevError, setCegNevError] = useState(false);
  const [adoszam, setAdoszam] = useState('');
  const [adoszamError, setAdoszamError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [megjegyzes, setMegjegyzes] = useState('');
  const [loader, setLoader] = useState(false);
  const [adatV, setAdatV] = useState(false);
  const [adatVError, setAdatVError] = useState(false);
  const [snackbar, setSnackBar] = useState(false);
  const [snackbarError, setSnackBarError] = useState(false);

  const getResult = () => {
    recaptchaRef.current.execute();
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar(false);
    setSnackBarError(false);
  };

  const firstNameValidation = () => {
    if (firstName === '') {
      setFirstNameError(true);
      return false;
    } else {
      setFirstNameError(false);
      return true;
    }
  };
  const surNameValidation = () => {
    if (surName === '') {
      setSurNameError(true);
      return false;
    } else {
      setSurNameError(false);
      return true;
    }
  };
  const cegNevValidation = () => {
    if (cegNev === '') {
      setCegNevError(true);
      return false;
    } else {
      setCegNevError(false);
      return true;
    }
  };

  const emailCheck = () => {
    if (email === '') {
      setEmailError('Kötelezően kitöltendő');
      return false;
    } else {
      if (emailValidation()) {
        setEmailError('');
        return true;
      } else {
        setEmailError('Nem megfelelő formátum');
        return false;
      }
    }
  };

  const emailValidation = () => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email);
  };

  const phoneValidation = () => {
    if (phone === '') {
      setPhoneError(true);
      return false;
    } else {
      setPhoneError(false);
      return true;
    }
  };

  const adoszamValidation = () => {
    if (adoszam === '') {
      setAdoszamError(true);
      return false;
    } else {
      setAdoszamError(false);
      return true;
    }
  };

  const adatVedelmiValidation = () => {
    if (adatV === false) {
      setAdatVError(true);
      return false;
    } else {
      setAdatVError(false);
      return true;
    }
  };

  const formvalidations = async () => {
    let firstNameResult = await firstNameValidation();
    let surNameResult = await surNameValidation();
    let cegNevResult = await cegNevValidation();
    let emailResult = await emailCheck();
    let phoneResult = await phoneValidation();
    let adoszamResult = await adoszamValidation();
    let adatVedelemiResult = await adatVedelmiValidation();

    if (
      firstNameResult === false ||
      surNameResult === false ||
      cegNevResult === false ||
      emailResult === false ||
      phoneResult === false ||
      adoszamResult === false ||
      adatVedelemiResult === false
    ) {
      setEmailSuccess(false);
      return false;
    } else {
      return true;
    }
  };

  const onChange = (token) => {
    setLoader(true);

    fetch(`${process.env.GATSBY_AZURE_URL}/request-offer`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        recaptcha: token,
        surname: surName,
        firstName,
        companyName: cegNev,
        taxNumber: adoszam,
        phoneNumber: phone,
        email,
        remark: megjegyzes,
        productKey: productKey
      })
    })
      .then((data) => {
        if (data.status === 200) {
          setLoader(false);
          setEmailSuccess(true);
        } else {
          setLoader(false);
          setEmailSuccess(false);
          setSnackBarError(true);
        }
      })
      .catch((e) => {
        setLoader(false);
        setEmailSuccess(false);
        setSnackBarError(true);
      });
  };

  const location_href = props.location.pathname;
  return (
    <div className="contactForm-wrapper">
      <LayoutHeaderFooter
        header_background_color={Secondary_color}
        header_secondary_color={Primary_color}
        SEO_title={"Ajánlatkérés: " + title}
        SEO_location={location_href}
      >
        <FirstComponentSmall
          primary_color={Primary_color}
          secondary_color={Secondary_color}
          title={title}
          onClick={() => {}}
        />
        <div className="body-content">
          <LayoutComponent background_color="#F7F7FA">
            <section>
              <div className="form">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={process.env.GATSBY_CAPTCHA_SITE_KEY}
                  onChange={onChange}
                />
                <h2>Ajánlatkérő űrlap</h2>
                <div className="fields">
                  <TextField
                    label={'Vezetéknév'}
                    error={!surNameError ? false : true}
                    variant="filled"
                    onChange={(e) => {
                      setSurname(e.target.value);
                      setSurNameError(false);
                      setEmailSuccess(false);
                    }}
                    value={surName}
                  />
                  <TextField
                    label={'Keresztnév'}
                    error={!firstNameError ? false : true}
                    variant="filled"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setFirstNameError(false);
                      setEmailSuccess(false);
                    }}
                    value={firstName}
                  />
                  <TextField
                    label={'Cégnév'}
                    error={!cegNevError ? false : true}
                    variant="filled"
                    onChange={(e) => {
                      setCegnev(e.target.value);
                      setCegNevError(false);
                      setEmailSuccess(false);
                    }}
                    value={cegNev}
                  />
                  <TextField
                    label={'Adószám'}
                    error={!adoszamError ? false : true}
                    variant="filled"
                    placeholder="12345678-1-12"
                    onChange={(e) => {
                      setAdoszam(e.target.value);
                      setAdoszamError(false);
                      setEmailSuccess(false);
                    }}
                    value={adoszam}
                  />
                  <TextField
                    label={'Telefonszám'}
                    variant="filled"
                    placeholder="+36301234567"
                    error={!phoneError ? false : true}
                    onChange={(e) => {
                      setPhone(e.target.value);
                      setPhoneError(false);
                      setEmailSuccess(false);
                    }}
                    value={phone}
                  />
                  <TextField
                    label={'Email cím'}
                    variant="filled"
                    placeholder="cmo@cmo.hu"
                    error={!emailError ? false : true}
                    helperText={!emailError ? ' ' : emailError}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError(false);
                      setEmailSuccess(false);
                    }}
                    value={email}
                  />
                </div>
                <TextField
                  label={'Megjegyzés'}
                  variant="filled"
                  className="text-area"
                  multiline
                  onChange={(e) => {
                    setMegjegyzes(e.target.value);
                    setEmailSuccess(false);
                  }}
                  value={megjegyzes}
                />
                <div
                  className={adatVError ? 'accept-adatv found' : 'accept-adatv'}
                >
                  <FormControlLabel
                    control={
                      <CMOCheckbox
                        onChange={(e) => {
                          if (e.target.checked) {
                            setAdatV(true);
                          } else {
                            setAdatV(false);
                          }
                        }}
                      />
                    }
                    label={
                      <div className="accept-adatv-text">
                        Elolvastam és elfogadom az{' '}
                        <a
                          href={'https://cmo.hu/adatkezelesi_tajekoztato.pdf'}
                          target={'__blank'}
                          rel={'noopener noreferrer nofollow'}
                        >
                          Adatkezelési tájékoztatót
                        </a>
                      </div>
                    }
                  />
                </div>
                <Button
                  variant="contained"
                  background_color={'#5107FF'}
                  text={"Ajánlatkérés küldése!"}
                  disabled={emailSuccess ? true : false}
                  color={'white'}
                  onClick={async () => {
                    if (await formvalidations()) {
                      getResult();
                    }
                  }}
                ></Button>
                {footNote && <div className="footNote">{footNote}</div>}
                <div style={{marginTop: '20px'}} >
                  {loader ? (
                    <ClockLoader
                      loading={loader}
                      css={override}
                      size={88}
                      color={'#4CAF50'}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <div style={{marginTop: '20px'}}>
                  {emailSuccess ? (
                    <SuccessCheckMark
                      id={`id_request_offer_${productKey}`}
                      text={
                        'Köszönjük a kapcsolatfelvételt, csapatunk 1-2 munkanapon belül felveszi veled a kapcsolatot!'
                      }
                    />
                  ) : (
                    ''
                  )}
                </div>
                <Snackbar
                  open={snackbarError}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  style={{ paddingTop: '100px' }}
                  autoHideDuration={6000}
                  onClose={handleSnackBarClose}
                >
                  <Alert onClose={handleSnackBarClose} severity="error">
                    Hiba történt!
                  </Alert>
                </Snackbar>
              </div>

              <div id="recaptcha">
                This site is protected by reCAPTCHA and the Google{' '}
                <span>
                  <a href="https://policies.google.com/privacy">
                    Privacy Policy
                  </a>{' '}
                </span>
                and{' '}
                <span>
                  <a href="https://policies.google.com/terms">
                    Terms of Service
                  </a>{' '}
                </span>
                apply.
              </div>
            </section>
            <section>
              <h2>Vagy szeretnél inkább Te megkeresni&nbsp;minket?</h2>
              <ul>
                <li>
                  <a
                    className="contact"
                    href={`tel:+ 3614433923`}
                    target={'__blank'}
                    rel={'noopener noreferrer nofollow'}
                  >
                    <PhoneIcon />
                    <div className="contact-content">
                      <p>+ 36 1 443 39 23</p>
                      <p className="sub">hétfőtől péntekig 9-16 óráig</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    className="contact"
                    href={`mailto:info@cmo24.com`}
                    target={'__blank'}
                    rel={'noopener noreferrer nofollow'}
                  >
                    <MailIcon />
                    <div className="contact-content">
                      <p>info@cmo24.com</p>
                      <p className="sub">hétfőtől péntekig 9-16 óráig</p>
                    </div>
                  </a>
                </li>
              </ul>
            </section>
          </LayoutComponent>
        </div>
      </LayoutHeaderFooter>
    </div>
  );
};

export default RequestForm;
